<template>
  <div class="FullPano">
    <Krpano ref="Pano" :blend="blend" :pano-id="(pano||{}).guid" height="100%" :krpanoSettings="krpanoSettings" @krpanoReady="onKrpanoReady" :sceneVars="sceneVars" :onLoadComplete="onXmlComplete" @setingPano="setingPano">
      <slot></slot>
    </Krpano>
  </div>
</template>
<script>
  import Krpano from '../../Krpano'
  import SimpleModal from '../../SimpleModal'
  import PanoMap from '../../Map/PanoMap'
  import uuid from 'uuid'

  export default {
    scrollToTop: true,
    components: {
      Krpano,
      SimpleModal,
      PanoMap,
    },
    model: {
      prop: 'info',
      event: 'change'
    },
    props: {
      config: {
        default() {
          return {}
        }
      },
    },
    inject: {
      publicData: {
      },
      getFunc: {}
    },
    data() {
      return {
        krpano: null,
        gyrpState: false,
        cleanState: false,
        krpanoInterface: null,
        Animations: {},
        hotspots: [],
        hotspotObjs: {},
        loadCompleted: false,
        hotspotClickTimer: null,
        inVRMode: false,
        panoVideo: false,
        videoEvetName: null,
      }
    },
    computed: {
      krpanoSettings() {
        var settings = {
          //passQueryParameters: true
          vars: {
          }
        }
        if (this.config.autorotate !== false) {
          settings.vars['autorotate.enabled'] = true
          settings.vars['autorotate.speed'] = 7
          settings.vars['autorotate.accel'] = 1
          settings.vars['autorotate.waittime'] = 5
        }
        return settings
      },
      autorotate() {
        return this.config.autorotate
      },
      dropHotspot() {
        return this.config.dropHotspot
      },
      hotspotFadeIn() {
        if (this.config.hotspotFadeIn == undefined) {
          return true
        }
        return this.config.hotspotFadeIn
      },
      useQuery() {
        if (this.config.useQuery == null) {
          return true
        }
        return this.config.useQuery
      },
      blend() {
        return this.pano.blend
      },
      panoQuery() {
        return this.publicData.panoQuery || {}
      },
      pano() {
        return this.publicData.pano || { hotspots: [] }
      },
      tour() {
        return this.publicData.tour
      },
      info() {
        return this.publicData.panoInfo || {}
      },
      sceneVars() {
        var rq = {}
        if (this.useQuery) {
          for (var i in this.$route.query) {
            switch (i) {
              case 'fov':
                rq['view.fov'] = this.$route.query[i]
                break
              case 'vLookAt':
                rq['view.vlookat'] = this.$route.query[i]
                break
              case 'hLookAt':
                rq['view.hlookat'] = this.$route.query[i]
                break
              case 'architectural':
                rq['view.architectural'] = this.$route.query[i]
                break
              case 'pannini':
                rq['view.pannini'] = this.$route.query[i]
                break
              case 'distortion':
                rq['view.distortion'] = this.$route.query[i]
                break
              case 'blend':
                this.pano.blend = this.$route.query[i]
                break
              default:
                rq[i] = this.$route.query[i]
                break
            }
          }
        }
        console.log('vars data', this.pano)
        if (this.pano) {
          return {
            //'view.fovmin': 70,
            'view.fovmax': 150,
            'view.maxpixelzoom': 1.0,
            'view.fovtype': 'MFOV',
            'view.fov': this.pano.fov || 120,
            'view.vlookat': this.pano.vLookAt || 0,
            'view.hlookat': this.pano.hLookAt || 0,
            'view.architectural': this.pano.architectural || 0,
            'view.pannini': this.pano.pannini || 0,
            'view.distortion': this.pano.distortion || 0,
            //'view.hlookatmin': -120,
            //'view.hlookatmax': 120,
            'view.limitview': this.pano.vLookAtMin != 0 || this.pano.vLookAtMax != 0 ? 'range' : 'auto',
            'view.vlookatmin': this.pano.vLookAtMin,
            'view.vlookatmax': this.pano.vLookAtMax,
            ...rq,
            ...this.panoQuery
          }
        }
        return {}
      },
      scale() {
        if (this.$refs.Scale) {
          return this.$refs.Scale.isMobile ? 0.5 : 1
        }
        return 1
      }
    },
    watch: {
      config(val, oldval) {
        if (this.krpano) {
          if (val.autorotate === false) {
            this.krpano.set('autorotate.enabled', false)
          } else {
            this.krpano.set('autorotate.enabled', true)
          }
        }
        if (val.clearAll === true) {
          this.unSetHotspots(this.getHotspots())
          this.unSetHotspots(this.getBlocks())
          this.removeSnowPlugin()
        } else if (oldval.clearAll === true) {
          this.setBlocks()
          this.setWeather()
          this.setHotspots()
          this.setVideos()
        }
        if (val.enableBlock != oldval.enableBlock || val.dropHotspot != oldval.dropHotspot) {
          setTimeout(() => {
            this.setBlocks()
            this.setHotspots()
            this.setVideos()
          })
        }
      },
      pano(val, oldval) {
        setTimeout(() => {
          if (val.guid == oldval.guid && val != oldval) {
            if (this.$refs.Pano) {
              this.$refs.Pano.setPano()
            }
          }
          this.changeInfo()
        })
      },
      info(val, oldval) {
        console.log('info', val)
        if (val) {
          if (val.gyrpState != undefined && val.gyrpState != this.gyrpState) {
            if (val.gyrpState) {
              this.enableGyrp()
            } else {
              this.disableGyrp()
            }
          }
          if (val.cleanState != undefined && val.cleanState != this.cleanState) {
            console.log({ pano: this.pano, publicData: this.publicData })
            if (val.cleanState) {
              this.enableClean()
            } else {
              this.disableClean()
            }
          }
        }
      },
    },
    created() {
    },
    mounted() {
      this.$emit('ready', {
        vm: this,
        events: {
        },
        funcs: {
          enterVR: this.enterVR,
          getKrpano: this.getKrpano,
          callKrpano: this.callKrpano,
          resetView: this.resetView,
          getView: this.getView,
          getKrpanoSize: this.getKrpanoSize,
          getLoadCompleted: this.getLoadCompleted,
          makeScreenshot: this.krpano_makeScreenshot,
          setWeather: this.setWeather,
          updateHotspot: this.updateHotspot,
          setHotspots: this.setHotspots,
          setHotspot: this.setHotspot,
          tweenHotspot: this.tweenHotspot,
          stopTweenHotspot: this.stopTweenHotspot,
          setBlocks: this.setBlocks,
          setVideos: this.setVideos,
          setVideos: this.setVideos,
          setPostprocessing: this.changePostprocessing,
          setView: this.setView,
          tweenView: this.tweenView,
          stopTweenView: this.stopTweenView,
          removeHotspots: this.unSetHotspots,
          removeHotspot: this.unSetHotspot,
          dragHotspot: this.dragHotspot,
          isInVRMode: this.isInVRMode,
          isPanoVideo: this.isPanoVideo,
          getVideoPlayer: this.getVideoPlayer,
          getKrpanoHotspot: this.getKrpanoHotspot,
        }
      })
      if (!(this.pano || {}).guid) {
        this.loadCompleted = true
      }
    },
    destroyed() {
    },
    methods: {
      getVideoPlayer() {
        if (!this.krpano) return null
        return this.krpano.get('plugin[video]')
      },
      isPanoVideo() {
        return this.panoVideo
      },
      videoEvent(name) {
        this.$emit(`video${name}`)
      },
      isInVRMode() {
        return this.inVRMode
      },
      getLoadCompleted() {
        return this.loadCompleted
      },
      setingPano() {
        for (var i in this.hotspotObjs) {
          this.hotspotObjs[i].destroy()
          delete this.hotspotObjs[i]
        }
        this.loadCompleted = false
      },
      krpano_makeScreenshot(func, width = 1000, height = 500, type = 'blob', hotspots = false) {
        if (this.krpano) {
          //console.log(this.krpano.call(`set(Screenshot, krpano.webGL.makeScreenshot(1000, 500, false, 'jpeg'))`))
          //console.log(this.krpano.call(`screenshotcanvas = krpano.webGL.makeScreenshot(0, 0, true, "canvas", 0, null, sizeinfo, makeScreenshotCache);`))
          //console.log(this.krpano.get('screenshotcanvas'))
          //var img = this.krpano.webGL.makeScreenshot(1000, 500, false, 'jpeg')
          //console.log(img)
          if (this.krpanoInterface) {
            var img = this.krpanoInterface.webGL.makeScreenshot(width, height, hotspots, "canvas")
            console.log(img)
            switch (type) {
              case 'blob':
                img.toBlob((blob) => {
                  console.log(blob)
                  if (func) {
                    func(blob)
                  }
                }, "image/jpeg", 0.95);
                break
              case 'imageUrl':
                if (func) {
                  func(img.toDataURL())
                }
                break
              default:
            }
          } else {
            this.krpano.call('addplugin(pluginInterface)')
            window.krpanopluginready = () => {
              this.krpanoInterface = this.krpano.get('plugin[pluginInterface].krpanointerface')
              console.log(this.krpanoInterface)
              //this.krpano.call('removeplugin(pluginInterface,true)')

              var img = this.krpanoInterface.webGL.makeScreenshot(width, height, hotspots, "canvas")
              console.log(img)
              switch (type) {
                case 'blob':
                  img.toBlob((blob) => {
                    console.log(blob)
                    if (func) {
                      func(blob)
                    }
                  }, "image/jpeg", 0.95);
                  break
                case 'imageUrl':
                  if (func) {
                    func(img.toDataURL())
                  }
                  break
                default:
              }
            }
            this.krpano.set('plugin[pluginInterface].onloaded', `js(krpanopluginready());`)
            this.krpano.set('plugin[pluginInterface].url', '%SWFPATH%/plugins/pluginInterface.js')
          }
        }
      },
      getView() {
        if (!this.krpano) {
          return {}
        }
        return this.krpano.get('view')
      },
      getKrpanoSize() {
        if (!this.krpano) return
        return { width: this.krpano.offsetWidth, height: this.krpano.offsetHeight, }
      },
      getKrpano() {
        return this.krpano
      },
      callKrpano(f) {
        if (!this.krpano) return
        this.krpano.call(`${f.name}(${this.getarcstr(f.args)})`)
      },
      getarcstr(args) {
        if (!args || args.length <= 0) {
          return ''
        }
        var s = ''
        for (var i in args) {
          s += args[i] + ','
        }
        return s, substring(0, basic.length - 1)
      },
      resetView() {
        if (!this.krpano) return
        var vars = this.sceneVars()
        for (var i in vars) {
          this.krpano.set(`${i}`, `${vars[i]}`);
        }
      },
      tweenView(obj) {
        if (!this.krpano) return
        var view, time, tweentype
        view = { ...obj }
        if (view.time) {
          time = view.time
          delete view.time
        }
        if (view.tweentype) {
          tweentype = view.tweentype
          delete view.tweentype
        }
        for (var i in view) {
          var str = `tween(view.${i},${view[i]},${time || ''},${tweentype || ''})`
          console.log(str)
          this.krpano.call(str);
        }
      },
      stopTweenView(obj) {
        if (!this.krpano) return
        var view, time, tweentype
        view = { ...obj }
        if (view.time) {
          time = view.time
          delete view.time
        }
        if (view.tweentype) {
          tweentype = view.tweentype
          delete view.tweentype
        }
        for (var i in view) {
          this.krpano.call(`stoptween(view.${i})`);
        }
      },
      setView(view) {
        if (!this.krpano) return
        for (var i in view) {
          this.krpano.set(`view.${i}`, `${view[i]}`);
        }
      },
      changePostprocessing(pp) {
        this.addPostprocessing(pp || this.pano.postprocessing)
        this.$forceUpdate()
      },
      addPostprocessing(postprocessing) {
        if (!this.krpano) return
        if (!postprocessing) {
          this.removePostprocessing()
          return
        }
        //亮度
        if (this.krpano.get('plugin[pp_light]') == null) {
          this.krpano.call('addplugin(pp_light)')
          this.krpano.set('plugin[pp_light].url', '%SWFPATH%/plugins/pp_light.js')
        }
        for (const i in postprocessing.light) {
          this.krpano.set('plugin[pp_light].' + i, postprocessing.light[i])
        }
        //锐化
        if (this.krpano.get('plugin[pp_sharpen]') == null) {
          this.krpano.call('addplugin(pp_sharpen)')
          this.krpano.set('plugin[pp_sharpen].url', '%SWFPATH%/plugins/pp_sharpen.js')
        }
        for (const i in postprocessing.sharpen) {
          this.krpano.set('plugin[pp_sharpen].' + i, postprocessing.sharpen[i])
        }
        //模糊
        if (this.krpano.get('plugin[pp_blur]') == null) {
          this.krpano.call('addplugin(pp_blur)')
          this.krpano.set('plugin[pp_blur].url', '%SWFPATH%/plugins/pp_blur.js')
        }
        for (const i in postprocessing.blur) {
          this.krpano.set('plugin[pp_blur].' + i, postprocessing.blur[i])
        }
      },
      removePostprocessing() {
        this.krpano.call('removeplugin(pp_light,true)')
        this.krpano.call('removeplugin(pp_sharpen,true)')
        this.krpano.call('removeplugin(pp_blur,true)')
      },

      changeInfo() {
        this.$emit('changePublicData', { panoInfo: this.getInfo() })
      },
      getInfo() {
        return {
          gyrpState: this.gyrpState,
          cleanState: this.cleanState,
        }
      },
      onXmlComplete() {
        this.$emit('xmlComplete')
        if (this.krpano.get('plugin[video]')) {
          this.panoVideo = true
          this.$emit('panoVideoEnable')
        } else {
          this.panoVideo = false
          this.$emit('panoVideoDisable')
        }
        this.setBlocks()
        this.changePostprocessing()
        this.setWeather()
        this.intro()
        //重置panoQuery
        this.getFunc('changePublicData')({
          panoQuery: {
          }
        })
      },
      intro() {
        if (!this.krpano) return
        this.stopLittlePlanetIntro()
        //this.unSetHotspots()
        console.log('intro', this.pano)
        if (this.config.littlePlanetIntro === false) {
          this.krpano.set('events[lp_events].onloadcomplete', `js(${this.$refs.Pano.getFuncName(() => {
            this.onPanoIntoComplete()
          })}())`)
        } else if (this.pano.littlePlanetIntro) {
          this.littlePlanetIntro()
        } else {
          this.krpano.set('events[lp_events].onloadcomplete', `js(${this.$refs.Pano.getFuncName(() => {
            this.onPanoIntoComplete()
          })}())`)
        }
      },
      setContextmenu() {
        if (!this.krpano) return
        var menuXml = `
<krpano>
<contextmenu fullscreen="false" versioninfo="true">
    <item name="fs" caption="全屏浏览"     onclick="set(fullscreen,true);"      showif="fullscreen == false" />
    <item name="ef" caption="退出全屏"     onclick="set(fullscreen,false);"     showif="fullscreen == true" />
    <item name="cc" caption="切换控制模式" onclick="skin_changecontrolmode();"  separator="true" />
    <item name="nv" caption="一般视角"     onclick="skin_view_normal();"        showif="view.vlookatrange == 180" separator="true"      />
    <item name="fv" caption="鱼眼视角"     onclick="skin_view_fisheye();"       showif="view.vlookatrange == 180" devices="flash|webgl" />
    <item name="sv" caption="立体视角"     onclick="skin_view_stereographic();" showif="view.vlookatrange == 180" devices="flash|webgl" />
    <item name="av" caption="构架视角"     onclick="skin_view_architectural();" showif="view.vlookatrange == 180"                       />
    <item name="pv" caption="超广角视角"   onclick="skin_view_pannini();"       showif="view.vlookatrange == 180" devices="flash|webgl" />
    <item name="lp" caption="小行星视角"   onclick="skin_view_littleplanet();"  showif="view.vlookatrange == 180" devices="flash|webgl" />
  </contextmenu>


  <action name="skin_changecontrolmode">
    switch(control.mouse, moveto, drag);
    switch(control.touch, moveto, drag);
  </action>

  <action name="skin_view_look_straight">
    if(view.vlookat LT -80 OR view.vlookat GT +80,
      tween(view.vlookat, 0.0, 1.0, easeInOutSine);
      tween(view.fov,     100, distance(150,0.8));
      );
  </action>

  <action name="skin_view_normal">
    skin_view_look_straight();
    tween(view.architectural, 0.0, distance(1.0,0.5));
    tween(view.pannini,       0.0, distance(1.0,0.5));
    tween(view.distortion,    0.0, distance(1.0,0.5));
  </action>

  <action name="skin_view_fisheye">
    skin_view_look_straight();
    tween(view.architectural, 0.0,  distance(1.0,0.5));
    tween(view.pannini,       0.0,  distance(1.0,0.5));
    tween(view.distortion,    0.35, distance(1.0,0.5));
  </action>

  <action name="skin_view_architectural">
    skin_view_look_straight();
    tween(view.architectural, 1.0, distance(1.0,0.5));
    tween(view.pannini,       0.0, distance(1.0,0.5));
    tween(view.distortion,    0.0, distance(1.0,0.5));
  </action>

  <action name="skin_view_stereographic">
    skin_view_look_straight();
    tween(view.architectural, 0.0, distance(1.0,0.5));
    tween(view.pannini,       0.0, distance(1.0,0.5));
    tween(view.distortion,    1.0, distance(1.0,0.8));
  </action>

  <action name="skin_view_pannini">
    skin_view_look_straight();
    tween(view.architectural, 0.0, distance(1.0,0.5));
    tween(view.pannini,       1.0, distance(1.0,0.8));
    if(view.distortion LT 0.1,
      tween(view.distortion, 1.0, distance(1.0,0.8));
      );
  </action>

  <action name="skin_view_littleplanet">
    tween(view.architectural, 0.0, distance(1.0,0.5));
    tween(view.pannini,       0.0, distance(1.0,0.5));
    tween(view.distortion,    1.0, distance(1.0,0.8));
    tween(view.fov,           150, distance(150,0.8));
    tween(view.vlookat,        90, distance(100,0.8));
    add(new_hlookat, view.hlookat, 123.0);
    tween(view.hlookat, get(new_hlookat), distance(100,0.8));
  </action>
</krpano>
`
        this.krpano.call(`loadxml(${menuXml},null,MERGE|KEEPSCENES,null)`)
      },
      setWebvr() {
        if (!this.krpano) return
        var menuXml = `
<krpano>
<include url="%FIRSTXML%/plugins/webvr.xml"/>
<plugin name="WebVR" mobilevr_fake_support="true" webvr_onavailable="removelayer(webvr_enterbutton); webvr.loadsettings();" onexitvr="webvr_onexitvr(); layer[stereomodes].selectitembyname(item1);"/>
</krpano>
`
        this.krpano.call(`loadxml(${menuXml},null,MERGE|KEEPSCENES,null)`)
      },
      enterVR(onexitvr) {
        if (!this.krpano) return
        //this.enableClean()

        this.krpano.call('webvr.enterVR();')
        this.inVRMode = true
        this.$emit('enterVR')


        this.krpano.set('webvr.onexitvr', `js(${this.$refs.Pano.getFuncName(() => {
          this.krpano.call('webvr_onexitvr();')
          if (onexitvr) {
            onexitvr()
          }
          this.$emit('exitVR')

          //this.disableClean()
          this.inVRMode = false
        })}())`)
      },
      onPanoIntoComplete() {
        //var vars = {
        //  'view.fovmin': 70,
        //  'view.fovmax': 150,
        //  'view.maxpixelzoom': 1.0,
        //  'view.fovtype': 'MFOV',
        //  //'view.hlookatmin': -120,
        //  //'view.hlookatmax': 120,
        //  'view.limitview': this.pano.vLookAtMin != 0 || this.pano.vLookAtMax != 0 ? 'range' : 'auto',
        //  'view.vlookatmin': this.pano.vLookAtMin,
        //  'view.vlookatmax': this.pano.vLookAtMax,
        //}
        //for (var i in vars) {
        //  this.krpano.set(i, vars[i])
        //}
        if (!this.info.cleanState) {
          this.setHotspots()
        }
        this.setVideos()

        if (this.panoVideo) {
          this.getVideoPlayer().play()
        }
        this.$emit('loadCompleted')
        console.log('FloadCompleted')

        this.loadCompleted = true
      },
      setWeather(weather) {
        var w = weather || this.pano.weather
        this.removeSnowPlugin()
        switch (w) {
          case '小雨':
            this.addSnowPlugin({
              mode: 'rain',
              blendmode: 'normal',
              flakes: '2000',
              color: '0x7FAFFF',
              speed: '3.5',
              shake: '1.0',
              speedvariance: '1.0',
              spreading: '3.0',
              rainwidth: '1.0',
              rainalpha: '0.5',
              wind: '2.0'
            })
            break
          case '大雨':
            this.addSnowPlugin({
              mode: 'rain',
              blendmode: 'normal',
              flakes: '4000',
              color: '0x7FAFFF',
              speed: '4.1',
              shake: '0.0',
              speedvariance: '1.5',
              spreading: '4.0',
              rainwidth: '1.5',
              rainalpha: '0.3',
              wind: '3.0'
            })
            break
          case '小雪':
            this.addSnowPlugin({
              mode: 'snow',
              blendmode: 'normal',
              flakes: '4000',
              color: '0xFFFFFF',
              speed: '1.0',
              shake: '4.0',
              speedvariance: '2.0',
              spreading: '4.0',
              wind: '0.0'
            })
            break
          case '大雪':
            this.addSnowPlugin({
              mode: 'image',
              imageurl: '/images/snowball.png',
              blendmode: 'normal',
              flakes: '2000',
              imagescale: '0.5',
              speed: '1.0',
              shake: '4.0',
              speedvariance: '2.0',
              spreading: '2.0',
              wind: '0.0'
            })
            break
          case '雪花':
            this.addSnowPlugin({
              mode: 'image',
              imageurl: '/images/snowflake.png',
              blendmode: 'add',
              flakes: '2000',
              imagescale: '0.4',
              speed: '0.5',
              shake: '8.0',
              speedvariance: '2.0',
              spreading: '2.0',
              wind: '0.0'
            })
            break
          default:
        }

      },
      stopLittlePlanetIntro() {
        if (!this.krpano) return
        this.krpano.call('stoptween(view.hlookat|view.vlookat|view.fov|view.distortion)')
        this.krpano.call('stoptween(view.fovmax)')
        this.krpano.call('stoptween(view.vlookatmin)')
        this.krpano.call('stoptween(view.vlookatmax)')
        this.krpano.set('view.distortion', this.pano.distortion || 0)
      },
      onMouseDown() {
        if (!this.krpano) return
        this.krpano.call('autorotate.stop()')
      },
      onclick() {
        var m = this.krpano.get('mouse')
        //this.krpano.call(`screentosphere(${m.clickx},${m.clicky},toh,tov)`)
        this.krpano.call(`screentosphere(mouse.x, mouse.y, toh, tov);`)
        m.toh = this.krpano.get('toh')
        m.tov = this.krpano.get('tov')
        this.$emit('click', m)
      },
      onsingleclick() {
        console.log('ClickTimer on', this.hotspotClickTimer, new Date().getTime())
        if (this.hotspotClickTimer) {
          return
        }
        this.$emit('onsingleclick')
      },
      ondoubleclick() {
        this.$emit('ondoubleclick')
      },
      onviewchange() {
        this.$emit('onviewchange')
      },
      onKrpanoReady(krpano) {
        console.log('onKrpanoReady', krpano)
        this.krpano = krpano
        this.krpano.set('events.onclick', `js(${this.$refs.Pano.getFuncName(this.onclick)}())`)
        this.krpano.set('events.onsingleclick', `js(${this.$refs.Pano.getFuncName(this.onsingleclick)}())`)
        this.krpano.set('events.ondoubleclick', `js(${this.$refs.Pano.getFuncName(this.ondoubleclick)}())`)
        this.krpano.set('events.onmousedown', `js(${this.$refs.Pano.getFuncName(this.onMouseDown)}())`)
        this.krpano.set('events.onviewchange', `js(${this.$refs.Pano.getFuncName(this.onviewchange)}())`)
        window.krpano = krpano
        this.setContextmenu()
        this.setWebvr()
      },
      setHotspots() {
        this.cleanHotspot()
        this.addHotspots(this.getHotspots(true))
      },
      setHotspot(item) {
        this.addHotspots([item])
      },
      setBlocks() {
        this.cleanHotspot()
        this.addHotspots(this.getBlocks())
      },
      setVideos() {
        this.cleanHotspot()
        this.addHotspots(this.getVideos())
      },
      setPolygonals() {
        this.cleanHotspot()
        this.addHotspots(this.getPolygonals())
      },
      cleanHotspot() {
        for (var i in this.hotspotObjs) {
          if (!this.getHotspotByGuid(this.hotspotObjs[i].data.guid)) {
            console.log('cleanHotspot', i, this.hotspotObjs[i])
            this.hotspotObjs[i].destroy()
            delete this.hotspotObjs[i]
          }
        }
      },
      addHotspots(items) {
        if (!this.krpano) return

        console.log({ addHotspots: items })
        for (var i in items) {
          console.log({ ii: items[i] })
          if (this.hotspotObjs[items[i].guid]) {
            this.hotspotObjs[items[i].guid].update(items[i])
          } else {
            console.log('1')
            var hotspot = new Hotspot(this, items[i])
            hotspot.on('all', this.hotspotEvent)
            console.log({ hotspot })
            this.hotspotObjs[items[i].guid] = hotspot
          }
        }
      },
      tweenHotspot(obj) {
        if (!this.krpano) return
        console.log({ tweenHotspot: obj })
        var hd = { ...obj }
        var time, tweentype, guid
        if (hd.time) {
          time = hd.time
          delete hd.time
        }
        if (hd.tweentype) {
          tweentype = hd.tweentype
          delete hd.tweentype
        }
        if (hd.guid) {
          guid = hd.guid
          delete hd.guid
        }
        if (this.hotspotObjs[guid]) {
          this.hotspotObjs[guid].tween(hd, time, tweentype)
        }
      },
      stopTweenHotspot(obj) {
        if (!this.krpano) return
        console.log({ tweenHotspot: obj })
        var hd = { ...obj }
        var guid
        if (hd.guid) {
          guid = hd.guid
          delete hd.guid
        }
        if (this.hotspotObjs[guid]) {
          this.hotspotObjs[guid].stopTween(hd)
        }
      },
      getHotspotByGuid(guid) {
        for (var i in this.pano.hotspots) {
          if (this.pano.hotspots[i].guid == guid) {
            return this.pano.hotspots[i]
          }
        }
        return null
      },
      getKrpanoHotspot(guid) {
        return this.hotspotObjs[guid]
      },
      hotspotEvent(name, obj) {
        if (this.hotspotClickTimer) {
          return
        }
        var h = obj.data
        this.$emit(`hotspot${name}`, h, obj)
        if (name == 'Click') {
          if (this.hotspotClickTimer) {
            clearTimeout(this.hotspotClickTimer)
          }
          this.hotspotClickTimer = setTimeout(() => { this.hotspotClickTimer = null }, 500)
          console.log('ClickTimer set', this.hotspotClickTimer, new Date().getTime())
        }
      },
      dragHotspot(item) {
        if (this.hotspotObjs[item.guid]) {
          this.hotspotObjs[item.guid].dragHotspot((ath, atv) => {
            this.$emit("hotspotMoved", { item, ath, atv })
          })
        }
      },
      getHotspots(paid) {
        return this.pano.hotspots.filter((v) => v.type == "Image" && paid ? !(v.linkType == "Link" && !this.pano.isPro) : true);
      },
      getBlocks() {
        return this.pano.hotspots.filter((v) => v.type == 'Block')
      },
      getVideos() {
        return this.pano.hotspots.filter((v) => v.type == 'Video')
      },
      getPolygonals() {
        return this.pano.hotspots.filter((v) => v.type == 'Polygonal')
      },
      unSetHotspots(hotspots) {
        for (const i in hotspots) {
          const item = hotspots[i]
          this.unSetHotspot(item)
        }
      },
      unSetHotspot(item) {
        if (this.hotspotObjs[item.guid]) {
          this.hotspotObjs[item.guid].destroy()
          delete this.hotspotObjs[item.guid]
        }
      },
      enableClean() {
        this.unSetHotspots(this.getHotspots())
        this.cleanState = true
        this.changeInfo()
      },
      disableClean() {
        this.cleanState = false
        this.setHotspots()
        this.changeInfo()
      },
      enableRain() {
        const setting = {
          mode: 'rain',
          blendmode: 'normal',
          flakes: '2000',
          color: '0x7FAFFF',
          speed: '3.5',
          shake: '1.0',
          speedvariance: '1.0',
          spreading: '3.0',
          rainwidth: '1.0',
          rainalpha: '0.5',
          wind: '2.0'
        }
        this.addSnowPlugin(setting)
      },
      enableGyrp() {
        this.gyrpState = true
        this.addGyro()
        this.changeInfo()
      },
      disableGyrp() {
        this.gyrpState = false
        this.removeGyroPlugin()
        this.changeInfo()
      },
      addGyro() {
        const setting = {
          keep: 'true',
          enabled: 'true',
          friction: 'auto', //0.0 =无摩擦/阻尼，0.99 =非常强的阻尼 值越高，阻尼越大，但是运动也会被延迟更多。
        }
        this.addGyroPlugin(setting)
      },
      addSnowPlugin(snowSetting) {
        if (!this.krpano) return
        if (this.krpano.get('plugin[snow]') != null) {
          this.krpano.call('removeplugin(snow,true)')
        }
        this.krpano.call('addplugin(snow)')
        this.krpano.set('plugin[snow].url', '%SWFPATH%/plugins/snow.js')
        for (const i in snowSetting) {
          this.krpano.set('plugin[snow].' + i, snowSetting[i])
        }
      },
      removeSnowPlugin() {
        if (!this.krpano) return
        this.krpano.call('removeplugin(snow,true)')
      },
      addGyroPlugin(gyroSetting) {
        if (!this.krpano) return
        if (this.krpano.get('plugin[gyro]') != null) {
          this.krpano.call('removeplugin(gyro,true)')
        }
        this.krpano.call('addplugin(gyro)')
        this.krpano.set('plugin[gyro].url', '%SWFPATH%/plugins/gyro2.js')
        for (const i in gyroSetting) {
          this.krpano.set('plugin[gyro].' + i, gyroSetting[i])
        }
      },
      removeGyroPlugin() {
        if (!this.krpano) return
        this.krpano.call('removeplugin(gyro,true)')
      },

      littlePlanetIntro() {
        if (!this.krpano) return
        const lpScene = this.krpano.get('xml.scene')
        const lpHlookat = this.krpano.get('view.hlookat')
        const lpVlookat = this.krpano.get('view.vlookat')
        const lpVlookatMin = this.krpano.get('view.vlookatmin')
        const lpVlookatMax = this.krpano.get('view.vlookatmax')
        const lpFov = this.krpano.get('view.fov')
        const lpFovmax = this.krpano.get('view.fovmax')
        const lpLimitview = this.krpano.get('view.limitview')
        this.krpano.set('view.fovmax', 170)
        this.krpano.set('view.limitview', 'lookto')
        this.krpano.set('view.vlookatmin', 90)
        this.krpano.set('view.vlookatmax', 90)
        this.krpano.call(`lookat(calc(${lpHlookat} - 180), 90, 150, 1, 0, 0);`)
        //window.littleplanetintro_onloadcomplete = () => {
        var func = () => {
          setTimeout(() => {
            console.log(this.krpano.get('progress.progress'))
          }, 250)
          setTimeout(() => {
            if (lpScene === this.krpano.get('xml.scene')) {
              this.krpano.set('control.usercontrol', 'off')
              this.krpano.set('view.limitview', 'auto')
              this.krpano.set('view.vlookatmin', null)
              this.krpano.set('view.vlookatmax', null)
              this.krpano.call(`tween(view.hlookat|view.vlookat|view.fov|view.distortion, calc('' + ${lpHlookat} + '|' + ${lpVlookat} + '|' + ${lpFov} + '|' + 0.0),
            3.0, easeOutQuad,
            set(control.usercontrol, all);
            tween(view.fovmax, ${lpFovmax});
            set(view.limitview, ${lpLimitview});
            set(view.vlookatmin, -90);
            set(view.vlookatmax, 90);
            tween(view.vlookatmin, ${lpVlookatMin});
            tween(view.vlookatmax, ${lpVlookatMax});
            js(${this.$refs.Pano.getFuncName(this.onPanoIntoComplete)}());
            );`)
            }
          }, 500)
        }
        if (this.preload) {
          func()
        } else {
          this.krpano.set('events[lp_events].onloadcomplete', `js(${this.$refs.Pano.getFuncName(func)}())`)
        }
        //}
        //this.krpano.set('events[lp_events].onloadcomplete', 'js(littleplanetintro_onloadcomplete())')
      },
      autoRotate() {
        if (!this.krpano) return
        this.krpano.set('autorotate.enabled', 'true')
      },
      stopAutoRotate() {
        if (!this.krpano) return
        this.krpano.set('autorotate.enabled', 'false')
      },
    },
  }
  class Hotspot {
    constructor(vm, data) {
      this.guid = uuid.v4()
      this.vm = vm
      this.krpano = vm.krpano
      this.eventName = 'event_' + this.guid
      this.emitEvent = this.getEmitEvent()
      window[this.eventName] = this.emitEvent
      this.animationCrop = this.getAnimationCrop()
      this.krHotspot = {}
      this.update(data)
      return this;
    }
    getEmitEvent() {
      return (name, type) => {
        if (this.events && this.events['all']) {
          for (var i in this.events['all']) {
            this.events['all'][i](name, this)
          }
        }
        if (this.events && this.events[name]) {
          for (var i in this.events[name]) {
            this.events[name][i](this)
          }
        }
        if (this.events && this.events[`${name},${type}`]) {
          for (var i in this.events[`${name},${type}`]) {
            this.events[`${name},${type}`][i](this)
          }
        }
      }
    }
    on(name, func, type) {
      if (!this.events) {
        this.events = {}
      }
      if (type) {
        if (!this.events[`${name},${type}`]) {
          this.events[`${name},${type}`] = []
        }
        if (this.events[`${name},${type}`].indexOf(func) != -1) {
          return
        }
        this.events[`${name},${type}`].push(func)
      } else {
        if (!this.events[name]) {
          this.events[name] = []
        }
        if (this.events[name].indexOf(func) != -1) {
          return
        }
        this.events[name].push(func)
      }
    }
    off(name, func) {
      if (this.events[name]) {
        var i = this.events[name].indexOf(func)
        if (i != -1) {
          this.events[name].splice(index, 1)
        }
      }
    }
    update(data) {
      console.log('update', this)
      this.data = data
      this.krHotspot = this.setkrHotspotDatas()
      console.log({ events: this.events })
      for (var i in this.krHotspot) {
        this.addHotspot({ ...this.krHotspot[i] })
      }
    }
    tween(data, time, type) {
      console.log('tween', this, data)
      this.data = { ...this.data, ...data }
      this.krHotspot = this.setkrHotspotDatas(data)
      console.log({ events: this.events, krHotspot: this.krHotspot })
      for (var i in this.krHotspot) {
        this.tweenHotspot({ ...this.krHotspot[i] }, time, type)
      }
    }
    stopTween() {
      console.log('update', this)
      this.krHotspot = this.setkrHotspotDatas()
      console.log({ events: this.events })
      for (var i in this.krHotspot) {
        this.stopTweenHotspot({ ...this.krHotspot[i] })
      }
    }
    newDefault(type, obj) {
      var item = this.data
      return {
        name: `hotspot_${item.guid}`,
        enabled: item.enabled == undefined ? true : item.enabled,
        zorder: 3,
        ath: item.ath || 0,
        atv: item.atv || 0,
        distorted: item.distorted,
        scale: item.scale,
        zoom: item.zoom,
        rotate: item.rotate,
        rz: item.rx || 0,
        rx: item.ry || 0,
        ry: item.rz || 0,
        alpha: item.alpha || 1,
        'onover': `js(${this.eventName}(Over,${type}))`,
        'onhover': `js(${this.eventName}(Hover,${type}))`,
        'onout': `js(${this.eventName}(Out,${type}))`,
        'ondown': `js(${this.eventName}(Down,${type}))`,
        'onup': `js(${this.eventName}(Up,${type}))`,
        'onclick': `js(${this.eventName}(Click,${type}))`,
        'onloaded': `js(${this.eventName}(Loaded,${type}))`,
        ...obj
      }
    }
    setkrHotspotDatas(d) {
      var item = d || this.data
      var datas = {}
      datas.default = this.newDefault('default')
      switch (item.type) {
        case 'Image':
          if (item.icon) {
            datas.default.url = item.icon.imageUrl
            datas.default.edge = item.icon.edge
            if (item.icon.isAnimation) {
              this.on('Loaded', this.animationCrop, 'default')
            }
            var iw = item.icon.width, ih = item.icon.height
            if (item.showTitle && item.title != null && item.title != "") {
              datas.title = this.newDefault('title', {
                name: `hotspot_${item.guid}_title`,
                zorder: 4,
                type: "text",
                html: item.title,
                css: 'font-size:30px;color:#FFFFFF;',
                txtshadow: "2 2 0 0x000000 1",
                //css: 'font-size:30px;color:#FFFFFF;text-shadow:4px 4px 4px #000, -4px -4px 4px #000, 4px -4px 4px #000, -4px 4px 4px #000;',
                scale: item.scale * 1,
                bg: false,
                rz: 0,
                rx: 0,
                ry: 0,
                rotate: 0,
                //bgcolor: "0x000000",
                //bgalpha: "0",
                //  bgroundedge: "20",
                //padding: "4 20",
                //  edge: item.icon.edge,
                //  vcenter: "true",
                //  padding: "0",
                //  wordwrap: "false",
                //  mergedalpha: "true",
              })
              let p = [
                item.icon.textOffsetX,
                item.icon.textOffsetY,
                (parseFloat(item.rotate) * Math.PI) / 180,
              ];
              p = this.changeAngle(p);
              datas.title.ox = p[0] * item.scale
              datas.title.oy = p[1] * item.scale
              var tedge = this.calcEdge(item.icon.textEdge, parseFloat(item.rotate))
              datas.title.edge = tedge
            }
          } else {
            datas.default.type = "text"
            datas.default.html = item.title
            datas.default.css = "font-size:12px; color:#FFF;text-shadow:2px 2px 2px #000, -2px -2px 2px #000, 2px -2px 2px #000, -2px 2px 2px #000;"
            //datas.default.bg= 'false'
            datas.default.bgalpha = "0.7"
            datas.default.bgcolor = "0x000000"
            datas.default.bgroundedge = 10
            datas.default.padding = "4 20"

          }
          break
        case 'Block':
          datas.default.zorder = 1
          datas.default.enabled = this.vm.config.enableBlock || false,
            datas.default.url = item.file.url || item.icon.imageUrl
          break
        case 'Video':
          datas.default.url = '%VIEWER%/plugins/videoplayer.js'
          datas.default.videourl = item.file.url
          datas.default.loop = item.loop
          if (item.chromakeyColor) {
            datas.default.chromakey = `${item.chromakeyColor.replace("#", "0x")}|${item.threshold}|${item.smoothing}`;
          }
          break
        case 'Polygonal':
          datas.default.points = item.points
        default:
      }

      return datas
    }
    changeAngle(param) {
      if (param[0] != 0 || param[1] != 0) {
        const x = param[0];
        const y = param[1];
        const tha1 = param[2];
        const value = Math.sqrt(x * x + y * y);
        const cos1 = x / value;
        const sin1 = y / value;
        const cos2 = Math.cos(tha1);
        const sin2 = Math.sin(tha1);
        const cos3 = cos1 * cos2 - sin1 * sin2;
        const sin3 = sin1 * cos2 + cos1 * sin2;
        param[0] = (value * cos3).toFixed(2);
        param[1] = (value * sin3).toFixed(2);
        return param;
      }
      return param;
    }
    calcEdge(edge, angle) {
      let e;
      const fx = [
        "top",
        "righttop",
        "right",
        "rightbottom",
        "bottom",
        "leftbottom",
        "left",
        "lefttop",
      ];
      e = fx.findIndex((val) => val == edge);
      if (e < 0) {
        return edge;
      }
      const offset = 22.5;
      const c = parseFloat(angle) + offset;
      let f = Math.floor(c / 45);
      f = e + f;
      do {
        if (f >= 0 && f < 8) {
          break;
        }
        if (f < 0) {
          f += 8;
        }
        if (f >= 8) {
          f -= 8;
        }
      } while (true);
      return fx[f];
    }
    dragHotspot(callback) {
      var item = this.data
      if (!this.krpano) return;
      console.log(item);
      var id = item.guid;
      var names = []
      for (var i in this.krHotspot) {
        names.push(this.krHotspot[i].name)
      }

      var ath = this.krpano.get(`hotspot[${names[0]}].ath`);
      var atv = this.krpano.get(`hotspot[${names[0]}].atv`);
      var spheretoscreen = this.krpano.spheretoscreen(ath, atv);
      var hotspotcenterx = spheretoscreen.x;
      var hotspotcentery = spheretoscreen.y;
      var stagex = this.krpano.get(`mouse.stagex`);
      var stagey = this.krpano.get(`mouse.stagey`);
      var drag_adjustx = stagex - hotspotcenterx;
      var drag_adjusty = stagey - hotspotcentery;
      var t = setInterval(() => {
        if (names.some((name) => {
          return this.krpano.get(`hotspot[${name}].pressed`)
        })) {
          var stagex = this.krpano.get(`mouse.stagex`);
          var stagey = this.krpano.get(`mouse.stagey`);
          var dx = stagex - drag_adjustx;
          var dy = stagey - drag_adjusty;
          var screentosphere = this.krpano.screentosphere(dx, dy);
          var ath = screentosphere.x;
          var atv = screentosphere.y;
          for (var i in names) {
            this.krpano.set(`hotspot[${names[i]}].ath`, ath);
            this.krpano.set(`hotspot[${names[i]}].atv`, atv);
          }
          callback(ath, atv)
          //this.$emit("hotspotMoved", { item, ath, atv });
        } else {
          clearInterval(t);
        }
      }, 10);
    }
    togglepause() {
      var hotspotName = this.krHotspot.default.name
      this.krpano.call(`hotspot[${hotspotName}].togglepause()`);
    }
    addHotspot(hotspotSetting) {
      if (!this.krpano) return;
      var points = hotspotSetting.points
      if (hotspotSetting.points) {
        delete hotspotSetting.points
      }
      console.log("addHotspot");
      let name = hotspotSetting.name;
      delete hotspotSetting.name;
      if (!this.krpano.get(`hotspot[${name}]`)) {
        this.krpano.call(`addhotspot(${name})`);
      }
      for (const i in hotspotSetting) {
        this.krpano.set(`hotspot[${name}].${i}`, hotspotSetting[i]);
      }
      for (const i in points) {
        for (var j in points[i]) {
          this.krpano.set(`hotspot[${name}].point[${i}].${j}`, points[i][j]);
        }
      }
    }
    tweenHotspot(hotspotSetting, time, tweentype) {
      if (!this.krpano) return;
      var points = hotspotSetting.points
      if (hotspotSetting.points) {
        delete hotspotSetting.points
      }
      let name = hotspotSetting.name;
      delete hotspotSetting.name;
      console.log('tweenHotspot', name, hotspotSetting)
      if (!this.krpano.get(`hotspot[${name}]`)) {
        this.krpano.call(`addhotspot(${name})`);
      }
      for (const i in hotspotSetting) {
        //if (i == 'chromakeyColor') {
        //  continue
        //}
        if (isNaN(Number(hotspotSetting[i]))) {
          this.krpano.set(`hotspot[${name}].${i}`, hotspotSetting[i]);
        } else {
          this.krpano.call(`tween(hotspot[${name}].${i},${hotspotSetting[i]},${time || ''},${tweentype || ''})`);
        }
      }
      for (const i in points) {
        for (var j in points[i]) {
          this.krpano.set(`hotspot[${name}].point[${i}].${j}`, points[i][j]);
          this.krpano.call(`tween(hotspot[${name}].point[${i}].${j},${points[i][j]},${time || ''},${tweentype || ''})`);
        }
      }
    }
    stopTweenHotspot(hotspotSetting) {
      if (!this.krpano) return;
      var points = hotspotSetting.points
      if (hotspotSetting.points) {
        delete hotspotSetting.points
      }
      console.log("addHotspot");
      let name = hotspotSetting.name;
      delete hotspotSetting.name;
      if (!this.krpano.get(`hotspot[${name}]`)) {
        this.krpano.call(`addhotspot(${name})`);
      }
      for (const i in hotspotSetting) {
        this.krpano.call(`stoptween(hotspot[${name}].${i})`);
      }
      for (const i in points) {
        for (var j in points[i]) {
          this.krpano.set(`hotspot[${name}].point[${i}].${j}`, points[i][j]);
          this.krpano.call(`stoptween(hotspot[${name}].point[${i}].${j})`);
        }
      }
    }
    getAnimationCrop() {
      return () => {
        //this.off('Loaded', this.animationCrop)
        if (this.Animation) {
          return
        }
        console.log('AnimationCrop', this)
        var framewidth = this.data.icon.width, frameheight = this.data.icon.height, framerate = this.data.icon.fps
        console.log('AnimationCrop', { framewidth, frameheight, framerate })
        if (this.Animation) {
          clearInterval(this.Animation)
        }
        var hotspotName = this.krHotspot.default.name
        const caller = this.krpano.get(`hotspot[${hotspotName}]`)
        const xframes = (caller.imagewidth / framewidth) || 0
        const frames = xframes * ((caller.imageheight / frameheight) || 0)
        let frame = 0
        console.log({ caller, crop: caller.crop, imagewidth: caller.imagewidth, framewidth, xframes, imageheight: caller.imageheight, frameheight, frames })
        this.krpano.set(`hotspot[${hotspotName}].crop`, `0|0|${framewidth}|${frameheight}`)
        this.Animation = setInterval(() => {
          if (caller.loaded) {
            frame++
            if (frame >= frames) {
              frame = 0
            }
            let xpos = frame % xframes
            let ypos = Math.floor(frame / xframes)
            xpos *= framewidth
            ypos *= frameheight
            this.krpano.set(`hotspot[${hotspotName}].crop`, `${xpos}|${ypos}|${framewidth}|${frameheight}`)
          }
        }, 1000 / framerate)
      }

    }
    destroy() {
      console.log("destroy Hotspot");
      for (var i in this.krHotspot) {
        this.krpano.call(`removehotspot(${this.krHotspot[i].name},true)`)
      }
      clearInterval(this.Animation)
      delete window['event_' + this.guid]
    }
  }

</script>
<style scoped>

  .FullPano {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 1;
  }
</style>
